import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const SingleImage = makeShortcode("SingleImage");
const Paper = makeShortcode("Paper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "no-end-to-the-arguments"
    }}>{`No end to the arguments?`}</h1>
    <TextCustom variant="h4" paragraph mdxType="TextCustom">
  Keep Calm & Carry On Loving Them
    </TextCustom>
    <p>{`If it feels like there has been no end to the arguments with your teenager, continue to show them that you still care for them and love them. You can do this by continuing to have your normal everyday conversations with them.`}</p>
    <p>{`Even if they shut you out, continue to let them know that you are there for them. Teenagers are constantly seeking their parents’ love and approval, even when it’s not obvious.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m5/10Hero.png" alt="keep calm poster" mdxType="SingleImage" />
    <Paper mdxType="Paper">
  <p>
    <b>
      It’s worth reflecting on your own upbringing and how you yourself were treated by your parents. These experiences
      can influence how you now treat your teenager. You may even hear your parent’s voice in the things you say to your
      own children.
    </b>
  </p>
  <p>
    For example, if your parents often criticised you, you might say similar things to your teenager without realising
    it.
  </p>
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      